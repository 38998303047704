<template>
  <div class="main__content-item main__content-item_center">
    <div class="main__content-item_l">
      <span>{{ title }}:</span>
    </div>

    <div class="main__content-item_r">
      <div class="inp-group__dropdown inp-group__xl">
        <v-select
          :clearable="clearable"
          :multiple="multiple || false"
          :options="options"
          :value="value"
          :label="label"
          @input="emitValue"
          @search="emitSearch"
          :placeholder="placeholder"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.inp-group__dropdown {
  height: auto;
  min-height: 30px;

  .v-select {
    padding-bottom: 3px;
  }
}
</style>
<style>
.vs__selected {
  background: #c2e900;
}
</style>
<script>
import vSelect from "vue-select";

export default {
  name: "FSelect",
  components: { vSelect },
  props: {
    value: {
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    options: {},
    title: {},
    multiple: {
      required: false,
      default: false,
      type: Boolean
    },
    label: {
      required: false,
      default: "title",
      type: String
    },
    clearable: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  methods: {
    emitValue(value) {
      this.$emit("input", value);
    },
    emitSearch(value) {
      this.$emit("onSearch", value);
    }
  }
};
</script>
